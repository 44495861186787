<template>
  <filtro-rapido v-model="abrirFiltro"
    ref="filtrosRapidos"
    :campos-formulario="camposFormulario"
    :metadados="getAcaoMetadado"
    :filtro-padrao="filtroPadrao"
    :ignora-inativos="false"
    :substituir-com-padrao="true"
    :ordenacao-campos="ordenacaoCampos"
    :entidade-workspace="entidadeWorkspace"
    @FiltroRapido__AplicaFiltros="aplicarFiltros"
    @FiltroRapido__HabilitaIconBadge="(value) => $emit('FiltroRapido__HabilitaIconBadge', value)"></filtro-rapido>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import FiltroRapido from '@/shared-components/filtro-rapido/FiltroRapido';
import NotasDebitoConsultaFiltrosCampos from '@/spa/notas-debito/consulta/NotasDebitoConsultaFiltrosCampos';

export default {
  name: 'NotasDebitoConsultaFiltros',
  props: {
    value: false,
  },
  mixins: [
    NotasDebitoConsultaFiltrosCampos,
  ],
  components: {
    FiltroRapido,
  },
  computed: {
    ...mapGetters('usuario', [
      'usuarioLogado',
    ]),
    ...mapGetters('metadados', [
      'getAcaoMetadado',
    ]),
    usuarioFornecedor() {
      return this.usuarioLogado.tipo === 'FORNECEDOR';
    },
  },
  data() {
    return {
      metadadoResource: this.$api.metadado(this.$resource),
      configuracaoResource: this.$api.notasDebitoConsultaConfiguracao(this.$resource),
      notaDebitoResource: this.$api.notasDebitoConsulta(this.$resource),

      abrirFiltro: false,
      ordenacaoCampos: null,
      filtrosValor: 0,
      filtrosData: 0,
      entidadeWorkspace: 'notas_debito_consulta',
      filtroPadrao: {
        data_inicio_cont: this.moment().subtract(3, 'month').startOf('month').format('YYYY-MM-DD'),
        data_fim_cont: this.moment().format('YYYY-MM-DD'),
      },
    };
  },
  watch: {
    value(val) {
      this.abrirFiltro = val;
    },
    abrirFiltro(val) {
      this.$emit('input', val);
    },
  },
  methods: {
    ...mapActions('metadados', [
      'setAcaoMetadado',
    ]),
    carregaCamposFiltros() {
      let promise = null;

      if (this.usuarioFornecedor) {
        promise = this.configuracaoResource.buscarCamposFiltroFornecedor();
      } else {
        promise = this.configuracaoResource.buscarCamposFiltro();
      }

      promise.then((res) => {
        this.ordenacaoCampos = res.data;
      }).catch((err) => {
        this.$error(this, err);
      });
    },
    aplicarFiltros(filtrosAplicados) {
      if (!filtrosAplicados || !this.filtroPeriodosValidos(filtrosAplicados)) {
        return;
      }
      if (!this.validaUmFiltroSelecionado(filtrosAplicados)) {
        this.$toast(this.$t('message.selecione_um_filtro'));
        return;
      }
      this.filtrosValor = 0;
      this.filtrosData = 0;
      Object.entries(filtrosAplicados).forEach(([key, value]) => {
        if ((`${key}`) === 'data_inicio_cont' || (`${key}`) === 'data_fim_cont' || (`${key}`) === 'data_inicio_venc' || (`${key}`) === 'data_fim_venc' || (`${key}`) === 'data_inicio_apu' || (`${key}`) === 'data_fim_apu') {
          this.filtrosData += 1;
        } else if ((`${key}`) !== 'numero' && (`${key}`) !== 'cod_acao' && (`${key}`) !== 'cod_apuracao') {
          if ((`${value}`) !== 'null' && (`${value}`) !== '') {
            this.filtrosValor += 1;
          }
        }
      });
      if (this.filtrosValor > 0 && this.filtrosData === 0) {
        this.$toast(this.$t('message.selecione_um_filtro_data'));
        return;
      }
      if (this.usuarioFornecedor) {
        filtrosAplicados.is_fornecedor = true;
      }
      this.$emit('NotasDebitoConsultaFiltros__AplicaFiltros', filtrosAplicados);
    },
    filtroPeriodosValidos(filtrosAplicados) {
      const filtroVencimentoValido = this.validaFiltroPeriodo(filtrosAplicados.data_inicio_venc,
        filtrosAplicados.data_fim_venc);
      const filtroContabilizacaoValido = this.validaFiltroPeriodo(filtrosAplicados.data_inicio_cont,
        filtrosAplicados.data_fim_cont);
      const filtroApuracaoValido = this.validaFiltroPeriodo(filtrosAplicados.data_inicio_apu,
        filtrosAplicados.data_fim_apu);
      return (filtroVencimentoValido && filtroContabilizacaoValido && filtroApuracaoValido);
    },
    validaFiltroPeriodo(campoInicio, campoFim) {
      if (campoInicio || campoFim) {
        let dtInicio = '';
        let dtFinal = '';
        dtInicio = this.moment(campoInicio, 'YYYY-MM-DD');
        dtFinal = this.moment(campoFim, 'YYYY-MM-DD');

        if (dtFinal.isBefore(dtInicio)) {
          this.$toast(this.$t('message.data_final_antes_inicial'));
          return false;
        }
        if ((campoInicio && !campoFim)
          || (!campoInicio && campoFim)) {
          this.$toast(this.$t('message.data_final_e_inicial'));
          return false;
        }
        return true;
      }
      return true;
    },
    validaUmFiltroSelecionado(filtrosAplicados) {
      let umFiltroObrigatorioSelecionado = false;

      Object.values(filtrosAplicados).every((value) => {
        if (value instanceof Array) {
          if (value && value.length) {
            umFiltroObrigatorioSelecionado = true;
            return false;
          }
        } else if (value) {
          umFiltroObrigatorioSelecionado = true;
          return false;
        }
        return true;
      });
      return umFiltroObrigatorioSelecionado;
    },
  },
  beforeMount() {
    this.setAcaoMetadado({
      resource: this.metadadoResource.definicaoAcao,
    });
  },
  mounted() {
    this.carregaCamposFiltros();
  },
};
</script>
<style>
  .NotasDebitoLista__Opcoes {
    padding: 0 0 0 20px;
    margin-top: 0;
  }
  .NotasDebitoLista__Opcoes div.v-input__slot,
  .NotasDebitoLista__Opcoes div.v-radio {
    margin-bottom: 0;
  }
  .NotasDebitoLista__Opcoes div.v-messages {
    display: none;
  }
  .NotasDebitoLista__Opcoes label {
    font-size: 14px;
  }
</style>
